html, body {
    margin: 0px;
    padding: 0px;
    height: 100%;
}
body {
    display: flex;
    flex-direction: column;
}
#root{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}